import React from 'react'

import { LinkMock } from '../../components/LinkMock'

import IconHTMLExample from './IconHTMLExample'

const SideNavBorderedHTMLExample = () => (
  <nav className="side-nav side-nav-bordered">
    <h2 className="h4">Työnhaku</h2>
    <ul className="list mb-0">
      <li className="list-item">
        <IconHTMLExample
          className="list-item-icon"
          name="ChevronRightIcon"
        />
        <span className="list-item-label">
          <LinkMock>Hae työpaikkaa</LinkMock>
        </span>
      </li>
      <li className="list-item">
        <IconHTMLExample
          className="list-item-icon"
          name="ChevronRightIcon"
        />
        <span className="list-item-label">
          <LinkMock>Ilmoittaudu työnhakijaksi</LinkMock>
        </span>
      </li>
    </ul>
  </nav>
)

export { SideNavBorderedHTMLExample }
