import React, { useEffect } from 'react'

import { LinkMock } from '../../components/LinkMock'

import IconHTMLExample from './IconHTMLExample'

const SideNavIconHTMLExample = () => {
  useEffect(() => {
    // @ts-expect-error
    window.StyleGuide.initializeToggle()
  }, [])

  return (
    <nav className="side-nav">
      <div className="side-nav-header">
        <h2 className="h4 mb-0">Työnhaku</h2>
      </div>
      <ul className="side-nav-list">
        <li>
          <LinkMock
            aria-current="page"
            className="side-nav-link"
          >
            <IconHTMLExample
              className="side-nav-link-icon"
              name="BulletIcon"
              size="lg"
            />
            Hae työpaikkaa
          </LinkMock>
        </li>
        <li>
          <LinkMock className="side-nav-link">
            <IconHTMLExample
              className="side-nav-link-icon"
              name="BulletIcon"
              size="lg"
            />
            Ilmoittaudu työnhakijaksi
          </LinkMock>
        </li>
      </ul>
    </nav>
  )
}

export { SideNavIconHTMLExample }
