import React, { useEffect } from 'react'

import { LinkMock } from '../../components/LinkMock'

import IconHTMLExample from './IconHTMLExample'

const SideNavCollapsibleHTMLExample = () => {
  useEffect(() => {
    // @ts-expect-error
    window.StyleGuide.initializeToggle()
  }, [])

  return (
    <nav className="side-nav">
      <div className="side-nav-header">
        <h2 className="h4 mb-0">Työnhaku</h2>
      </div>
      <ul className="side-nav-list">
        <li>
          <LinkMock className="side-nav-link open flex justify-content-between">
            Hae työpaikkaa
            <button
              aria-controls="collapsible-content-1"
              aria-expanded="true"
              className="side-nav-collapse-button btn-plain-primary"
              data-toggle="collapse"
              id="collapse-button-1"
              type="button"
            >
              <span className="btn-content">
                <IconHTMLExample
                  className="btn-icon side-nav-collapse-button-icon-expand"
                  name="ExpandIcon"
                  size="lg"
                />
                <IconHTMLExample
                  className="btn-icon side-nav-collapse-button-icon-collapse"
                  name="CollapseIcon"
                  size="lg"
                />
              </span>
            </button>
          </LinkMock>
          <ul
            className="side-nav-list"
            id="collapsible-content-1"
          >
            <li>
              <LinkMock className="side-nav-link">
                Ilmoituksen tekeminen
              </LinkMock>
            </li>
            <li>
              <LinkMock
                aria-current="page"
                className="side-nav-link flex justify-content-between"
              >
                <div className="flex justify-content-between">Päätös</div>
                <button
                  aria-controls="collapsible-content-2"
                  aria-expanded="true"
                  className="side-nav-collapse-button btn-plain-primary"
                  data-toggle="collapse"
                  id="collapse-button-2"
                  type="button"
                >
                  <span className="btn-content">
                    <IconHTMLExample
                      ariaLabel="expand"
                      className="btn-icon side-nav-collapse-button-icon-expand"
                      name="ExpandIcon"
                      size="lg"
                    />
                    <IconHTMLExample
                      ariaLabel="collapse"
                      className="btn-icon side-nav-collapse-button-icon-collapse"
                      name="CollapseIcon"
                      size="lg"
                    />
                  </span>
                </button>
              </LinkMock>
              <ul
                className="side-nav-list"
                id="collapsible-content-2"
              >
                <li>
                  <LinkMock className="side-nav-link">
                    Päätöksestä valittaminen
                  </LinkMock>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <LinkMock className="side-nav-link">
            Ilmoittaudu työnhakijaksi
          </LinkMock>
        </li>
      </ul>
    </nav>
  )
}

export { SideNavCollapsibleHTMLExample }
