import React from 'react'
import {
  BriefcaseIcon,
  Heading,
  ProfileIcon,
  SideNav,
  SideNavHeader,
  SideNavLink,
  SideNavList,
  SideNavListItem
} from '@te-digi/styleguide'

import { LinkMock } from '../../components/LinkMock'

const SideNavIconExample = () => (
  <SideNav>
    <SideNavHeader>
      <Heading
        level={2}
        size={4}
        noMargin
      >
        Työnhaku
      </Heading>
    </SideNavHeader>
    <SideNavList>
      <SideNavListItem>
        <SideNavLink
          active
          as={LinkMock}
          icon={<BriefcaseIcon />}
          to="#"
        >
          Hae työpaikkaa
        </SideNavLink>
      </SideNavListItem>
      <SideNavListItem>
        <SideNavLink
          as={LinkMock}
          icon={<ProfileIcon />}
          to="#"
        >
          Ilmoittaudu työnhakijaksi
        </SideNavLink>
      </SideNavListItem>
    </SideNavList>
  </SideNav>
)

export { SideNavIconExample }
