import React from 'react'

import {
  Link,
  List,
  ListItem,
  Paragraph,
  SideNav,
  SideNavCollapsibleListItem,
  SideNavHeader,
  SideNavLink,
  SideNavList,
  SideNavListItem
} from '@te-digi/styleguide'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'
import { SidebarWrapper } from '../../components/SidebarWrapper'
import { SideNavBorderedHTMLExample } from '../../examples/html/SideNavBorderedHTMLExample'
import { SideNavCollapsibleHTMLExample } from '../../examples/html/SideNavCollapsibleHTMLExample'
import { SideNavHTMLExample } from '../../examples/html/SideNavHTMLExample'
import { SideNavCollapsibleExample } from '../../examples/react/SideNavCollapsibleExample'
import { SideNavExample } from '../../examples/react/SideNavExample'
import { SideNavIconExample } from '../../examples/react/SideNavIconExample'
import { Code } from '../../components/Code'
import { SideNavIconHTMLExample } from '../../examples/html/SideNavIconHTMLExample'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="SideNav"
    components={[
      { component: SideNav },
      { component: SideNavHeader },
      { component: SideNavLink },
      { component: SideNavList },
      { component: SideNavListItem },
      { component: SideNavCollapsibleListItem }
    ]}
    status={[{ type: 'accessible', version: '15.1.0' }]}
  >
    <Section>
      <Paragraph>
        <Code>SideNav</Code> sisältää headerin ja listan navigaatiolinkkejä
        sivuille. Sivut on mahdollista järjestää toistensa alasivuiksi kolmeen
        tasoon.
      </Paragraph>
      <Playground
        example={SideNavExample}
        WrapperComponent={SidebarWrapper}
      />
      <Playground
        example={SideNavHTMLExample}
        format="html"
        WrapperComponent={SidebarWrapper}
      />
    </Section>
    <Section title={'Collapsible'}>
      <Paragraph>
        Yksittäisen sivun alasivut on mahdollista piilottaa collapsiblen alle
        käyttämällä <Code>SideNavCollapsibleListItem</Code> komponenttia.
      </Paragraph>
      <Playground example={SideNavCollapsibleExample} />
      <Playground
        example={SideNavCollapsibleHTMLExample}
        format="html"
        WrapperComponent={SidebarWrapper}
      />
    </Section>
    <Section title={'Ikonilla'}>
      <Paragraph>
        Sivuille on mahdollista lisätä myös ikoni käyttämällä <Code>icon</Code>
        -proppia <Code>SideNavLink</Code>:illä tai{' '}
        <Code>SideNavCollapsibleListItem</Code>:llä.{' '}
      </Paragraph>
      <Playground
        example={SideNavIconExample}
        WrapperComponent={SidebarWrapper}
      />
      <Playground
        example={SideNavIconHTMLExample}
        format="html"
        WrapperComponent={SidebarWrapper}
      />
    </Section>
    <Section title={'Muita käyttötapauksia'}>
      <Playground
        example={SideNavBorderedHTMLExample}
        format="html"
        WrapperComponent={SidebarWrapper}
      />
    </Section>
    <Section title="Saavutettavuus">
      <Paragraph>
        Semanttisesti komponentti tuottaa <Code>nav</Code>-elementin.
      </Paragraph>
      <Paragraph>Tarkistuslista:</Paragraph>
      <List variant="unordered">
        <ListItem>
          Varmista, että navigaatio noudattaa hierarkiaa ja on selvä myös
          näppäimistön ja ruudunlukijan käyttäjille.
        </ListItem>
        <ListItem>
          Jos sivulla on useampi navigointielementti, ne pitää erottaa
          toisistaan <Code>aria-label</Code>eilla.
        </ListItem>
        <ListItem>
          Huomioi sivunavigaation paikka sivulla: sivunavigaatio kannattaa käydä
          läpi ennen varsinaiseen sivuun liikkumista. Mikäli sivunavigaatio on
          pitkä, tarjoa siihen{' '}
          <Code>
            <Link href="/navigointi/skiplink/">SkipLink</Link>
          </Code>{' '}
          tai ohita se suoraan seuraaville sivuille siirryttäessä.
        </ListItem>
      </List>
    </Section>
  </Content>
)

export default Page
