import React, { forwardRef, ReactNode } from 'react'
import { Columns } from '@te-digi/styleguide'

interface SidebarWrapperProps {
  children: ReactNode
}

const SidebarWrapper = forwardRef<HTMLDivElement, SidebarWrapperProps>(
  ({ children }, ref) => (
    <Columns
      layout="sidebar"
      sidebar={<div ref={ref}>{children}</div>}
    >
      <div />
    </Columns>
  )
)

export { SidebarWrapper }
