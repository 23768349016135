import {
  Heading,
  SideNav,
  SideNavHeader,
  SideNavLink,
  SideNavList,
  SideNavListItem
} from '@te-digi/styleguide'
import React from 'react'

import { LinkMock } from '../../components/LinkMock'

const SideNavExample = () => (
  <SideNav>
    <SideNavHeader>
      <Heading
        level={2}
        size={4}
        noMargin
      >
        Työnhaku
      </Heading>
    </SideNavHeader>
    <SideNavList>
      {['Hae työpaikkaa', 'Ilmoittaudu työnhakijaksi'].map((item, index) => (
        <SideNavListItem key={item}>
          <SideNavLink
            as={LinkMock}
            open={index === 0}
            to="#"
          >
            {item}
          </SideNavLink>
          {item === 'Hae työpaikkaa' && (
            <SideNavList>
              {['Ilmoituksen tekeminen', 'Päätös'].map((item, index) => (
                <SideNavListItem key={item}>
                  <SideNavLink
                    active={index === 0}
                    as={LinkMock}
                  >
                    {item}
                  </SideNavLink>
                </SideNavListItem>
              ))}
            </SideNavList>
          )}
        </SideNavListItem>
      ))}
    </SideNavList>
  </SideNav>
)

export { SideNavExample }
