import React, { useState } from 'react'

import {
  Columns,
  Heading,
  SideNav,
  SideNavCollapsibleListItem,
  SideNavHeader,
  SideNavLink,
  SideNavList,
  SideNavListItem
} from '@te-digi/styleguide'

import { LinkMock } from '../../components/LinkMock'

const SideNavCollapsibleExample = () => {
  const [active, setActive] = useState(0)
  const [expanded, setExpanded] = useState<number[]>([])

  const expand = (id: number) => {
    const newExpanded = [...expanded]
    newExpanded.push(id)
    setExpanded(newExpanded)
  }

  const collapse = (id: number) => {
    const newExpanded = [...expanded]
    const index = newExpanded.findIndex(value => value === id)
    newExpanded.splice(index, 1)
    setExpanded(newExpanded)
  }

  return (
    <Columns
      layout={'sidebar'}
      sidebar={
        <SideNav>
          <SideNavHeader>
            <Heading
              level={2}
              noMargin
              size={4}
            >
              Työnhaku
            </Heading>
          </SideNavHeader>
          <SideNavList>
            <SideNavCollapsibleListItem
              active={active === 1}
              as={LinkMock}
              collapsibleContent={
                <SideNavList>
                  <SideNavListItem>
                    <SideNavLink
                      active={active === 2}
                      as={LinkMock}
                      onClick={() => setActive(2)}
                    >
                      Ilmoituksen tekeminen
                    </SideNavLink>
                  </SideNavListItem>
                  <SideNavCollapsibleListItem
                    active={active === 3}
                    as={LinkMock}
                    collapsibleContent={
                      <SideNavList>
                        <SideNavListItem>
                          <SideNavLink
                            active={active === 4}
                            as={LinkMock}
                            onClick={() => setActive(4)}
                          >
                            Päätöksestä valittaminen
                          </SideNavLink>
                        </SideNavListItem>
                      </SideNavList>
                    }
                    onClick={() => {
                      setActive(3)
                      !expanded.includes(3) && expand(3)
                    }}
                    onClickExpand={() => {
                      expanded.includes(3) ? collapse(3) : expand(3)
                    }}
                    open={expanded.includes(3)}
                  >
                    Päätös
                  </SideNavCollapsibleListItem>
                </SideNavList>
              }
              onClick={() => {
                setActive(1)
                !expanded.includes(1) && expand(1)
              }}
              onClickExpand={() =>
                expanded.includes(1) ? collapse(1) : expand(1)
              }
              open={expanded.includes(1)}
              to="#"
            >
              Hae työpaikkaa
            </SideNavCollapsibleListItem>
            <SideNavListItem>
              <SideNavLink
                active={active === 5}
                as={LinkMock}
                onClick={() => setActive(5)}
                to="#"
              >
                Ilmoittaudu työnhakijaksi
              </SideNavLink>
            </SideNavListItem>
          </SideNavList>
        </SideNav>
      }
    >
      <div />
    </Columns>
  )
}

export { SideNavCollapsibleExample }
