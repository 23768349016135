import React, { useEffect } from 'react'

import { LinkMock } from '../../components/LinkMock'

const SideNavHTMLExample = () => {
  useEffect(() => {
    // @ts-expect-error
    window.StyleGuide.initializeToggle()
  }, [])

  return (
    <nav className="side-nav">
      <div className="side-nav-header">
        <h2 className="h4 mb-0">Työnhaku</h2>
      </div>
      <ul className="side-nav-list">
        {['Hae työpaikkaa', 'Ilmoittaudu työnhakijaksi'].map((item, index) => (
          <li key={item}>
            <LinkMock className={`side-nav-link${index === 0 ? ' open' : ''}`}>
              {item}
            </LinkMock>
            {item === 'Hae työpaikkaa' && (
              <ul>
                {['Ilmoituksen tekeminen', 'Päätös'].map((item, index) => (
                  <li key={item}>
                    <LinkMock
                      aria-current={index === 0 ? 'page' : undefined}
                      className="side-nav-link"
                    >
                      {item}
                    </LinkMock>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </nav>
  )
}

export { SideNavHTMLExample }
